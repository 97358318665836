import api from '@apiInstance';

export default {
  add(data) {
    return api.post('/services', data);
  },
  get(id) {
    return api.fetch('/services/' + id);
  },
  list(params) {
    return api.fetch('/services', { params });
  },
  update(data) {
    return api.patch('/services', data);
  },
  delete(id) {
    return api.remove('/services', id);
  },
};
