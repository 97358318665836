<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Services"
              :data-source="dataSource"
              @on-delete="(v) => onDelete(v)"
              @on-open-modal="(v) => onOpenModal(v)"
              @on-update-cancel="(v) => loadSuppliers()"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in fields"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="showModal"
      centered
      @hide="closeModal()"
      size="lg"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <b-form-group label="Name" label-for="v-name">
            <b-form-input v-model="formData.name" />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Price" label-for="v-name">
            <b-form-input v-model.number="formData.price" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Description" label-for="v-name">
            <b-form-textarea row="2" v-model="formData.description" />
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer>
        <b-button
          size="sm"
          @click="closeModal()"
          variant="danger"
          class="bg-darken-4"
        >
          Cancel
        </b-button>
        <b-button
          size="sm"
          @click="onSubmit()"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import servicesApi from '@api/services';
import { queryParameters } from '@/schema';

export default {
  name: 'ServicesPage',
  components: {},
  data: () => ({
    totalCount: 0,
    page: 0,
    size: 0,
    isShowingMap: false,
    showModal: false,
    dataSource: [],
    fields: [
      { caption: 'Supplier ID', field: 'name' },
      { caption: 'Description', field: 'description' },
      { caption: 'Price', field: 'price' },
    ],
    formData: {},
  }),
  watch: {
    page(v) {
      this.loadSuppliers();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadSuppliers();
      }
    },
  },
  mounted() {
    this.loadSuppliers();
  },
  methods: {
    loadSuppliers() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      servicesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;
            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      let app;
      if (this.formData.id) {
        app = servicesApi.update(this.formData);
      } else {
        app = servicesApi.add(this.formData);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.loadSuppliers();
            this.closeModal();
          })
          .catch(() => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onOpenModal(event) {
      this.showModal = event.value;

      if (event.name === 'edit') {
        this.onEdit(event.data);
      }
    },
    onEdit(data) {
      this.$nextTick(() => {
        this.formData = Object.assign({}, data);
      });
    },
    onDelete(event) {
      servicesApi
        .delete(event)
        .then(() => {
          this.loadSuppliers();
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onGetPlace(v) {
      //
    },
    closeModal() {
      this.showModal = false;

      this.$nextTick(() => {
        this.formData = {};
      });
    },
  },
};
</script>

<style></style>
